import {React,useEffect} from "react";
import NavBar from "./NavBar";
import Landing from "./Landing";
import About from "./About";
import Experience from "./Experience";
import Contact from "./Contact";
import SocialLinks from "./SocialLinks";
import { ReactLenis, useLenis } from 'lenis/react'
import { useLocation } from "react-router-dom";



const Main = ({ setCircleSize }) => {




  return (
    <ReactLenis root>
      <div className="bg-[#1a1a1a]">
        <NavBar setCircleSize={setCircleSize} />
        <Landing setCircleSize={setCircleSize}/>
        <About />
        {/* <Projects /> */}
        <Experience />
        {/* <Contact /> */}
        {/* <SocialLinks /> */}
      </div>
    </ReactLenis >
  );
};

export default Main;
