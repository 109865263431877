import React, { useState, useEffect, useRef } from "react";


const projects = [
    {
        name: "Stock Broker",
        sourceCode: "https://github.com/aakashahpl/stock-broker",
        demoLink: "https://stock-broker-tau.vercel.app/",
        imageSrc: "/portfolio/stockBroker.png",
        about: {
            line1: "Web application that allows users to access stock data, execute buy/sell transactions, and receive real-time stock price updates and business news.",
            line2: ""
        }
    },
    {
        name: "Blog Website",
        sourceCode: "https://github.com/aakashahpl/ang-blogWebsite",
        demoLink: "https://blog-app-blue-xi.vercel.app/",
        imageSrc: "/portfolio/blog-website.png",
        about: {
            line1: "Developed server side of the application focusing on user management, blogs and comment handling ",
            line2: " - Utilized PassportJs, JWT for secure user management, and Multer for handling file uploads (multipart/form-data)."
        }
    },
    {
        name: "India Tourism",
        sourceCode: "https://github.com/aakashahpl/indiaTourism",
        demoLink: "",
        imageSrc: "/portfolio/tourism.jpg",
        about: {
            line1: "A tourism webiste which creates personalized travel itineraries based on user preferences.",
            line2: ""
        }
    },
    {
        name: "Skill Forge",
        sourceCode: "https://github.com/aakashahpl/Skill-forge",
        demoLink: "",
        imageSrc: "/portfolio/skillForge.jpg",
        about: {
            line1: "Educational web app that allows users to generate personalized programming courses using Google's Gemini API.",
            line2: " - Integrated course creation features based on custom prompts for any programming concept or language."
        }
    },
];


const Projects = ({ setCircleSize }) => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [imageIndex, setImageIndex] = useState(0);
    const [distortionLevel, setDistortionLevel] = useState(0);
    const lastScrollY = useRef(0);
    const animationFrameId = useRef(null);
    const resetTimeoutId = useRef(null);
    const isScrolling = useRef(false);

    useEffect(() => {
        if (scrollPosition < 1000) {
          setImageIndex(0);
        } else if (scrollPosition >= 1300 && scrollPosition < 1950) {
          setImageIndex(1);
        } else if (scrollPosition >= 1950 && scrollPosition < 3000) {
          setImageIndex(2);
        } else if (scrollPosition > 3000) {
          setImageIndex(3);
        }
      }, [scrollPosition]);

    useEffect(() => {
        let lastScrollTime = 0;
        const updateDistortion = (timestamp) => {
            const currentScrollY = window.scrollY;
            const scrollDiff = Math.abs(currentScrollY - lastScrollY.current);

            if (scrollDiff > 0) {
                const timeDiff = timestamp - lastScrollTime;
                const scrollSpeed = scrollDiff / timeDiff;

                const newDistortion = Math.min(scrollSpeed * 50, 1);
                setDistortionLevel(newDistortion);
                lastScrollTime = timestamp;
                isScrolling.current = true;

                if (resetTimeoutId.current) {
                    clearTimeout(resetTimeoutId.current);
                }
            } else if (isScrolling.current) {
                resetTimeoutId.current = setTimeout(() => {
                    setDistortionLevel(0);
                    isScrolling.current = false;
                }, 210);
            }

            lastScrollY.current = currentScrollY;
            animationFrameId.current = requestAnimationFrame(updateDistortion);
        };

        animationFrameId.current = requestAnimationFrame(updateDistortion);

        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };

        window.addEventListener("scroll", handleScroll, { passive: true });

        return () => {
            window.removeEventListener("scroll", handleScroll);
            if (animationFrameId.current) {
                cancelAnimationFrame(animationFrameId.current);
            }
            if (resetTimeoutId.current) {
                clearTimeout(resetTimeoutId.current);
            }
        };
    }, []);


    const getImageStyle = () => {
        return {
            filter: `blur(${distortionLevel * 2}px) brightness(${1 - distortionLevel * 0.2})`,
            transition: 'filter 0.3s ease-out', // Smooth transition for blur and brightness
            willChange: 'filter',
        };
    };
    return (
        <div className="flex flex-row min-h-screen">
            <div className="w-2/5 items-end justify-center flex flex-col">
                {projects.map((item, index) => (
                    <div key={index} className="h-screen flex items-end justify-center flex-col w-full">
                        <div className="w-4/5  flex flex-col items-start gap-6 -mr-32">
                            <h1 className="text-[5rem] w-full flex justify-start font-bold text-neutral-300 z-30 uppercase">{item.name}</h1>
                            <h2 className="text-xl flex justify-end w-10/12 z-30">{item.about.line1}</h2>
                            {/* <h2 className="text-lg flex justify-end w-10/12 z-30">{scrollPosition}</h2> */}
                        </div>
                    </div>
                ))}
            </div>
            <div className="w-3/5 z-10 relative">
                <div className="flex items-center h-[100vh] sticky top-0">
                    <img
                        onMouseEnter={() => setCircleSize(2)}
                        onMouseLeave={() => setCircleSize(1)}
                        src={projects[imageIndex].imageSrc}
                        className="w-10/12 rounded-md"
                        style={getImageStyle()}
                        alt={projects[imageIndex].name}
                    />
                </div>
            </div>
        </div>
    );
};

export default Projects;
