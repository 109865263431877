import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from "split-type";

gsap.registerPlugin(ScrollTrigger);

const About = () => {
    const textRef4 = useRef(null);
    const paragraph1Ref = useRef(null);
    const paragraph2Ref = useRef(null);

    useEffect(() => {
        // Title animation
        const split = new SplitType(textRef4.current, { types: "chars" });
        gsap.set(".char", { y: 50, opacity: 0 });
        gsap.to(".char", {
            scrollTrigger: {
                trigger: textRef4.current,
                start: "top 100%",
                toggleActions: "play none none none"
            },
            y: 0,
            opacity: 1,
            stagger: 0.03,
            duration: 0.3,
            ease: "power3.out"
        });

        // SVG animation timeline
        const tlSvg = gsap.timeline({
            scrollTrigger: {
                trigger: "#svg-container",
                start: "top 80%",
                end: "top 50%",
                toggleActions: "play none none none",
            },
        });

        tlSvg.fromTo(
            "#line1",
            { strokeDasharray: 559, strokeDashoffset: 559 },
            { strokeDashoffset: 0, duration: 1 }
        )
            .fromTo(
                "#line2",
                { strokeDasharray: 445, strokeDashoffset: 445 },
                { strokeDashoffset: 0, duration: 1 },
                "-=0.5"
            )
            .fromTo(
                "#line3",
                { strokeDasharray: 559, strokeDashoffset: 559 },
                { strokeDashoffset: 0, duration: 1 },
                "-=0.5"
            )
            .fromTo(
                "#line4",
                { strokeDasharray: 445, strokeDashoffset: 445 },
                { strokeDashoffset: 0, duration: 1 },
                "-=0.5"
            );

        // Paragraphs animation timeline
        const tlParagraphs = gsap.timeline({
            scrollTrigger: {
                trigger: paragraph1Ref.current,
                start: "top 80%",
                toggleActions: "play none none none"
            }
        });

        // Set initial states
        gsap.set([paragraph1Ref.current, paragraph2Ref.current], { 
            opacity: 0,
            y: 30
        });

        // Animate paragraphs sequentially
        tlParagraphs
            .to(paragraph1Ref.current, {
                opacity: 1,
                y: 0,
                duration: 1,
                ease: "power3.out"
            })
            .to(paragraph2Ref.current, {
                opacity: 1,
                y: 0,
                duration: 1,
                ease: "power3.out"
            }, "-=0.5"); // Start slightly before first paragraph finishes

    }, []);

    return (
        <div
            name="about"
            className="w-full h-screen bg-[#1a1a1a] text-white font-mulish flex"
        >
            <div className="max-w-screen-xl p-4 mx-auto flex flex-col justify-center w-full h-full gap-10">
                <div className="mb-20 text-center">
                    <p ref={textRef4} className="text-4xl inline font-mulish">ABOUT</p>
                </div>
                <div className="flex gap-10">
                    <div className="hidden lg:flex items-center relative h-[470px] overflow-hidden">
                        <div id="svg-container" className="flex items-center justify-center w-full absolute left-0">
                            <svg
                                width="446"
                                height="559"
                                viewBox="0 0 446 559"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <line id="line1" x1="0.5" y1="1" x2="0.499976" y2="559" stroke="white" strokeWidth="2" />
                                <line id="line2" y1="0.5" x2="445" y2="0.5" stroke="white" strokeWidth="2" />
                                <line id="line3" x1="445.5" y1="1" x2="445.5" y2="559" stroke="white" strokeWidth="2" />
                                <line id="line4" y1="558.5" x2="445" y2="558.5" stroke="white" strokeWidth="2" />
                            </svg>
                        </div>
                        <img
                            src="https://i.pinimg.com/736x/df/df/3d/dfdf3d42b36b383f5277d2734dd820d8.jpg"
                            alt="dogoos"
                            width={2000}
                        />
                    </div>
                    <div>
                        <p
                            ref={paragraph1Ref}
                            className="text-md lg:text-2xl mt-20"
                        >
                            "I'm a full-stack developer with expertise in Express, Node.js, React, Tailwind CSS, and Next.js. I specialize in building both the backend and frontend of web applications. Using Express and Node.js, I create robust APIs and backend functionality, while leveraging React, Next.js, and Tailwind CSS to develop responsive, user-friendly interfaces. My focus is on delivering scalable, high-performance applications by seamlessly integrating these technologies throughout the entire development lifecycle."
                        </p>
                        <br />
                        <p
                            ref={paragraph2Ref}
                            className="text-md lg:text-2xl"
                        >
                            I am familiar with tools like Git and GitHub and can work efficiently in a team. I have collaborated in various projects in both frontend and backend.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;