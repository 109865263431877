import React, { useEffect, useRef } from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { RxSlash } from "react-icons/rx";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from "split-type";

gsap.registerPlugin(ScrollTrigger);

const Contact = () => {
    const titleRef2 = useRef(null);
    const emailRef = useRef(null);

    useEffect(() => {
        // Title animation
        const titleSplit = new SplitType(titleRef2.current, { types: "chars" });
        gsap.set(titleRef2.current.querySelectorAll(".char"), { y: 50, opacity: 0 });
        gsap.to(titleRef2.current.querySelectorAll(".char"), {
            scrollTrigger: {
                trigger: titleRef2.current,
                start: "top 70%", 
                toggleActions: "play none none none",
                invalidateOnRefresh: true,
            },
            y: 0,
            opacity: 1,
            stagger: 0.03,
            duration: 1,
            ease: "power3.out",
            delay:2,
        });

        // Email animation
        const emailSplit = new SplitType(emailRef.current, { types: "chars" });
        gsap.set(emailRef.current.querySelectorAll(".char"), { y: 50, opacity: 0 });
        gsap.to(emailRef.current.querySelectorAll(".char"), {
            scrollTrigger: {
                trigger: emailRef.current,
                start: "top 70%",
                toggleActions: "play none none none"
            },
            y: 0,
            opacity: 1,
            stagger: 0.03,
            duration: 2,
            ease: "power3.out",
            delay: 0.3 // Slight delay after title animation
        });
    }, []);

    return (
        <div
            name="contact"
            className="mt-96 w-full min-h-screen bg-[#b8bab9] text-gray-100 flex justify-center items-center flex-col lg:flex-col mx-auto z-50 relative"
        >
            <div className="flex flex-col md:flex-row px-2">
                <div className="w-11/12 lg:w-8/12 justify-between flex flex-col gap-12">
                    <div className="mt-60 flex flex-col gap-14">
                        <h1
                            ref={titleRef2} 
                            className="text-2xl lg:text-[9rem] font-extrabold font-coign tracking-wide"
                        >
                            INTERESTED IN WORKING TOGETHER?
                        </h1>
                        <p className="flex flex-col lg:flex-row gap-2 md:gap-6">
                            <h2 
                                ref={emailRef}
                                className="text-xl lg:text-4xl mt-2 font-mulish"
                            >
                                aakashpatel315@gmail.com
                            </h2>
                            <a 
                                href="https://mail.google.com/mail/?view=cm&fs=1&to=aakashpatel315@gmail.com"
                                className="group text-white w-fit px-5 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-600 to-blue-500 cursor-pointer"
                            >
                                Send Mail
                                <span className="group-hover:rotate-90 duration-300">
                                    <MdOutlineKeyboardArrowRight size={25} className="ml-1" />
                                </span>
                            </a>
                        </p>
                    </div>
                </div>
                <img src="/lucky-cat.jpg" draggable="false" alt="lucky-cat" className="w-64 lg:w-auto mx-auto" />
            </div>
            <div className="flex w-full h-24 justify-center items-center">
                <div className="flex hover:text-neutral-400 font-semibold text-sm items-center text-white h-fit tracking-[0.2rem]">
                    <a
                        href="https://linkedin.com/in/aakashahpl"
                        className="hover:text-neutral-100 transition-colors duration-300"
                    >   
                        LINKEDIN
                    </a>
                    <RxSlash size={14} className="mx-2" />   
                    <a
                        href="https://github.com/aakashahpl"
                        className="hover:text-neutral-100 transition-colors duration-300"
                    >
                        GITHUB
                    </a>
                    <RxSlash size={14} className="mx-2" />
                    <a
                        href="https://github.com/aakashahpl"
                        className="hover:text-neutral-100 transition-colors duration-300"
                    >
                        INSTAGRAM
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Contact;