import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import TagSphere from "./TagSphere";
import html from "../assets/html.png";
import css from "../assets/css.png";
import javascript from "../assets/javascript.png";
import reactImage from "../assets/react.png";
import nextjs from "../assets/nextjs.png";
import graphql from "../assets/graphql.png";
import github from "../assets/github.png";
import tailwind from "../assets/tailwind.png";
import { FiExternalLink } from "react-icons/fi";
import Contact from "./Contact";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from "split-type";

gsap.registerPlugin(ScrollTrigger);

const Experience = () => {
  const titleRef = useRef(null);

  useEffect(() => {
    // Title animation
    const split = new SplitType(titleRef.current, { types: "chars" });
    gsap.set(".char", { y: 50, opacity: 0 });
    gsap.to(".char", {
      scrollTrigger: {
        trigger: titleRef.current,
        start: "top 100%",
        toggleActions: "play none none none"
      },
      y: 0,
      opacity: 1,
      stagger: 0.03,
      duration: 0.4,
      ease: "power3.out"
    });
  }, []);

  const techs = [
    {
      id: 1,
      src: html,
      title: "HTML",
      style: "shadow-orange-500",
    },
    {
      id: 2,
      src: css,
      title: "CSS",
      style: "shadow-blue-500",
    },
    {
      id: 3,
      src: javascript,
      title: "JavaScript",
      style: "shadow-yellow-500",
    },
    {
      id: 4,
      src: reactImage,
      title: "React",
      style: "shadow-blue-600",
    },
    {
      id: 5,
      src: tailwind,
      title: "Tailwind",
      style: "shadow-sky-400",
    },
    {
      id: 6,
      src: nextjs,
      title: "Next JS",
      style: "shadow-white",
    },
    {
      id: 7,
      src: graphql,
      title: "GraphQL",
      style: "shadow-pink-400",
    },
    {
      id: 8,
      src: github,
      title: "GitHub",
      style: "shadow-gray-400",
    },
  ];

  const skills = [
    "HTML",
    "CSS",
    "MySql",
    "JavaScript",
    "TypeScript",
    "TailwindCSS",
    "SASS",
    "Express",
    "MongoDB",
    "SQL",
    "React",
    "Angular",
    "Node.js",
    "AWS",
    "Postman",
    "ES6",
    "C/C++",
    "Webpack",
    "Git",
    "GitHub",
    "OOPS",
    "RESTful API"
  ]
  const options = {
    radius: window.innerWidth <= 768 ? 150 : 200, // Adjust radius based on screen width
    maxSpeed: 0.7
  }

  return (
    <div className="flex flex-col">
      <div      
        name="experience"
        className="bg-[#1a1a1a] max-w-screen min-h-screen flex my-10 sticky top-0 z-10"
      >
        <div className="p-4 flex flex-col text-white mt-44 gap-32 mx-auto">
          <div className="text-center">
            <p ref={titleRef} className="text-4xl font-mulish inline">
              EXPERIENCE
            </p>
          </div>

          <div className="flex flex-col lg:flex-row mx-auto lg:gap-32">
            <div className="flex flex-row gap-4 font-mulish">
              <div className="flex flex-col items-start">
                <div className="flex flex-col items-center">
                  <motion.div 
                    className="vline"
                    initial={{ height: '0rem' }}
                    whileInView={{ height: '5rem' }}
                    transition={{ duration: 1, ease: "linear" }} 
                    viewport={{ once: true }}
                  />
                  <div className="circle circle-black" />
                  <motion.div 
                    className="vline2"
                    initial={{ height: '0rem' }}
                    whileInView={{ delay: 2, height: '10rem' }}
                    transition={{ duration: 1, ease: "linear" }}
                    viewport={{ once: true }}
                  />
                  <div className="circle circle-black" />
                </div>
              </div>
              <div className="md:min-w-[32rem]">
                <div className="flex flex-row mt-[5rem] justify-between border-b-[1px] border-[#292929] pb-2 gap-2">
                  <a href="https://therightdoctors.com">
                    <motion.h3 
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{ delay: 0.5, duration: 1.2 }}
                      viewport={{ once: true, amount: 0.5 }}
                      className="text-md lg:text-2xl hover:text-cyan-600"
                    >
                      Full-Stack Developer
                    </motion.h3>
                    <motion.h3 
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{ delay: 0.5, duration: 1.2 }}
                      viewport={{ once: true, amount: 0.5 }}
                      className="text-sm lg:text-xl hover:text-cyan-600 flex flex-row items-center gap-2"
                    >
                      TheRightDoctors.com <FiExternalLink />
                    </motion.h3>
                  </a>
                  <div>
                    <h3 className="text-sm lg:text-lg">Telangana, India</h3>
                  </div>
                </div>
                <div className="flex flex-row mt-[5rem] justify-between border-b-[1px] border-[#303030] pb-2">
                  <a href="https://team-srm-hackathon.vercel.app/">
                    <motion.h3 
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{ delay: 0.5, duration: 1.2 }}
                      viewport={{ once: true, amount: 0.5 }}
                      className="text-md lg:text-2xl hover:text-cyan-600"
                    >
                      Full-Stack Developer
                    </motion.h3>
                    <motion.h3 
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{ delay: 0.5, duration: 1.2 }}
                      viewport={{ once: true, amount: 0.5 }}
                      className="text-sm lg:text-xl hover:text-cyan-600 flex flex-row items-center gap-2"
                    >
                      Team SRM Hackathon <FiExternalLink />
                    </motion.h3>
                  </a>
                  <div>
                    <h3 className="text-sm lg:text-lg">Chennai, India</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center mt-24 ml-2">
              <TagSphere texts={skills} options={options} />
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </div>
  );
};

export default Experience;