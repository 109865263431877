import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./components/Main";
import Work from "./components/Work";

function App() {
  const [circleSize, setCircle] = useState(1);

  // Function to toggle cursor size
  const setCircleSize = (size) => {
    setCircle(size);
  }

  useEffect(() => {
    const handleMouseMove = (e) => {

      document.documentElement.style.setProperty('--x', `${e.clientX}px`);
      document.documentElement.style.setProperty('--y', `${e.clientY}px`);

    };

    document.body.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.body.removeEventListener('mousemove', handleMouseMove);

    };
  }, []);


  useEffect(() => {

    const cursorElement = document.getElementById("circularcursor");
    if (cursorElement) {
      if (circleSize == 2) {
        console.log("working useEffect");
        cursorElement.classList.add("cursor-large");
      } else {
        cursorElement.classList.remove("cursor-large");
      }
    }


  }, [circleSize]);


  return (

    <Router>
      <div id="circularcursor" className="cursor-small flex justify-center items-center font-medium text-lg">
      </div>

      <Routes>
        <Route path="/" element={<Main setCircleSize={setCircleSize} />} />
        <Route path="/work" element={<Work setCircleSize={setCircleSize} />} />
      </Routes>
      
    </Router>

  );
}

export default App;
