import React from "react";
import { useEffect, useRef } from "react";
import { HiArrowDownRight } from "react-icons/hi2";
import { motion } from "framer-motion";
import { gsap } from "gsap";
import SplitType from "split-type";

const Home = ({ setCircleSize }) => {
  const textRef = useRef(null);
  const textRef2 = useRef(null);
  const textRef3 = useRef(null);
  const svgRef = useRef(null);

useEffect(() => {
    // Text animation
    const split = new SplitType(textRef.current, { types: "chars" });
    const split2 = new SplitType(textRef2.current, { types: "chars" });
    const split3 = new SplitType(textRef3.current, { types: "chars" });

    // Set initial state for text
    gsap.set(".char", { y: 50, opacity: 0 });

    // Text animation
    gsap.to(".char", {
      y: 0,
      opacity: 1,
      stagger: 0.02,
      delay: 3.4,
      duration: 0.4,
      ease: "power3.out",
    });

    // Logo animation
    const paths = svgRef.current.querySelectorAll('path');
    
    // Set initial state for both paths
    paths.forEach(path => {
      const length = path.getTotalLength();
      gsap.set(path, {
        strokeDasharray: length,
        strokeDashoffset: length,
        fill: 'transparent',
        stroke: 'white',
        strokeWidth: 2
      });
    });

    // Create timeline for simultaneous animation
    const tl = gsap.timeline({ delay: 3.5 });

    // Animate both paths simultaneously
    tl
      .to(paths, {
        strokeDashoffset: 0,
        duration: 1.2,
        ease: "power2.out"
      })
      .to(paths, {
        fill: 'white',
        duration: 0.3,
        ease: "power2.inOut"
      }, "+=0.05");

}, []);

  return (
    <div
      name="home"
      className="h-screen w-full bg-[#1a1a1a] z-10"
    >
      <div className="flex flex-col items-center justify-center h-full w-full lg:max-w-[80rem] mx-auto">
        <div className="h-auto flex flex-col justify-center items-center w-full overflow-hidden">
          <div className="text-white home-bg-text flex flex-row w-full items-center justify-center gap-[1px]">
            AAKASH PATEL
          </div>

          <div 
            className="flex flex-row gap-2 fixed z-[70] left-20 top-5 mix-blend-difference"
            onMouseEnter={() => setCircleSize(2)}
            onMouseLeave={() => setCircleSize(1)}
          >
            <svg 
              ref={svgRef}
              width="90" 
              height="80" 
              viewBox="0 0 163 160" 
              fill="none" 
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            >
              <g filter="url(#filter0_d_4_2)">
                <path d="M5 126L63 12L132.056 149.621C132.496 150.499 131.539 151.42 130.679 150.946L114.452 142.004C110.901 140.048 108.029 137.058 106.216 133.432L62 45L30 112H70C75.5397 112 80.6284 115.053 83.2353 119.941L87 127L5 126Z" />
              </g>
              <g filter="url(#filter1_d_4_2)">
                <path d="M72 8.47546C78.7689 4.26929 86.301 1.5537 94.1268 0.498009C101.953 -0.557686 109.904 0.0691402 117.486 2.33941C125.068 4.60968 132.117 8.47472 138.195 13.6936C144.272 18.9124 149.248 25.3732 152.811 32.6731C156.374 39.973 158.448 47.9555 158.904 56.123C159.36 64.2904 158.188 72.4678 155.462 80.1451C152.735 87.8225 148.512 94.8353 143.056 100.746C137.601 106.657 131.278 110.838 124 114L116.535 96.6559C121.449 94.521 125.887 91.3596 129.57 87.3687C133.254 83.3779 136.105 78.6432 137.946 73.4598C139.786 68.2765 140.577 62.7556 140.269 57.2414C139.962 51.7271 138.561 46.3378 136.156 41.4093C133.75 36.4808 130.391 32.1188 126.288 28.5953C122.185 25.0718 117.425 22.4624 112.306 20.9296C107.187 19.3968 101.819 18.9736 96.5354 19.6864C91.2518 20.3991 85.1666 22.2325 80.5966 25.0723L72 8.47546Z" />
              </g>
              <defs>
                <filter id="filter0_d_4_2" x="0.189636" y="10.8913" width="136.479" height="148.686" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_2" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_2" result="shape" />
                </filter>
                <filter id="filter1_d_4_2" x="68" y="0" width="95" height="122" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_2" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_2" result="shape" />
                </filter>
              </defs>
            </svg>
          </div>

          <div className="text-neutral-300 flex justify-end w-full font-mulish">
            <motion.div>
              <h3 className="text-md md:text-2xl flex items-center gap-2">
                <HiArrowDownRight />
                <div
                  ref={textRef}
                  onMouseEnter={() => setCircleSize(2)}
                  onMouseLeave={() => setCircleSize(1)}
                >
                  FULL STACK DEVELOPER
                </div>
              </h3>
              <h3 className="text-md md:text-2xl ml-8" ref={textRef2}>
                BASED IN INDIA
              </h3>
            </motion.div>
          </div>
        </div>

        <div className="w-full flex flex-row justify-between px-14 mt-6">
          <div className="flex flex-row gap-4"></div>
        </div>

        <div></div>
      </div>
    </div>
  );
};

export default Home;