import React from "react";
import Contact from "./Contact";
import {
  motion
} from "framer-motion"
// import { useLocation } from "react-router-dom";
import { ReactLenis, useLenis } from 'lenis/react'
import { WorkNavbar } from "./WorkNavbar";
import Projects from "./Projects";


const Work = ({ setCircleSize }) => {


  return (
    <ReactLenis root>

      <div className="bg-[#1a1a1a] min-h-screen w-full justify-center items-center text-neutral-200 font-mulish">
        <WorkNavbar setCircleSize={setCircleSize} />

        <motion.div initial={{ x: -300, opacity: 0 }} whileInView={{ x: 0, opacity: 1 }} transition={{ duration: 2, type: 'spring', delay: 0.5 }} viewport={{ once: true }}
          className="w-11/12 min-h-[300px] text-[10rem] uppercase font-coign justify-start px-20 align-middle items-center tracking-wide"
        >
          Recent Work
        </motion.div>

        <Projects setCircleSize={setCircleSize} />

        <Contact />
      </div>
    </ReactLenis >


  );
};

export default Work;
