import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link as Link1 } from "react-scroll";
import { Link as Link2 } from "react-router-dom";

const icon = {
  hidden: {
    opacity: 0,
    pathLength: 0,
    fill: "rgba(255, 255, 255, 0)"
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    fill: "rgba(255, 255, 255, 1)"
  }
};

const NavBar = ({ setCircleSize }) => {
  const [nav, setNav] = useState(false);

  const links = [
    {
      id: 1,
      link: "home",
    },
    {
      id: 2,
      link: "work",
      routerLink: "/work"
    },
    {
      id: 3,
      link: "experience",
    },
    {
      id: 4,
      link: "contact",
    },
  ];


  // make scroll invisible when user scrolls down
  const [prevScroll, setPrevScroll] = useState(0);
  const [showNav, setShowNav] = useState(true);

  const handleScroll = () => {
    const currentScroll = window.scrollY;
    if (currentScroll > prevScroll) {
      setShowNav(false);
    } else {
      setShowNav(true);
    }
    setPrevScroll(currentScroll);
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScroll]);


  return (
    <div className={`flex justify-between items-center hover-target fixed w-full z-100 top-0 h-24 pl-2 md:pl-20 text-gray-300 bg-[#1a1a1a] transition-transform duration-300 ease-in-out ${showNav ? "showNav" : "hideNav"}`}
      onMouseEnter={() => setCircleSize(2)}
      onMouseLeave={() => setCircleSize(0)}
    >

      <div className="flex flex-row justify-center items-center">
      </div>
      <div className=" h-full flex items-center justify-center">
        {/* <div className=" w-20 h-full bg-stone-200 -m-[1px]">
          <div className="h-full w-full bg-[#1a1a1a]">
          </div>
        </div> */}
        <div className=" h-full flex justify-end items-center px-20 ml-[-3px] text-neutral-200">
          <ul className="hidden md:flex gap-4 font-mulish tracking-wider">
            {links.map(({ id, link, routerLink }) => (
              <li
                key={id}
                className="px-6 cursor-pointer uppercase hover:scale-105 text-lg duration-200 "
              >
                <Link1 to={link} smooth duration={500}>
                  <Link2 to={routerLink}>

                    {link}
                  </Link2>
                </Link1>
              </li>
            ))}
          </ul>
          <div
            onClick={() => setNav(!nav)}
            className="cursor-pointer pr-4 text-gray-500 md:hidden"
          >
            {nav ? <FaTimes size={30} /> : <FaBars size={30} />}
          </div>

          {nav && (
            <ul className="flex flex-col justify-center items-center absolute top-0 left-0 w-full h-screen bg-gradient-to-b from-black to-gray-800 text-white">
              {links.map(({ id, link }) => (
                <li
                  key={id}
                  className="px-4 cursor-pointer capitalize py-6 text-4xl"
                >
                  <Link1
                    onClick={() => setNav(!nav)}
                    to={link}
                    smooth
                    duration={500}
                  >
                    {link}
                  </Link1>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default NavBar;
